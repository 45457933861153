import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import './menuBar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import hamburger from './bootstrap-icons/icons/list.svg';
import person from './bootstrap-icons/icons/person-fill.svg';
import home from './bootstrap-icons/icons/house-fill.svg';
import info from './bootstrap-icons/icons/info-circle-fill.svg';
import phone from './bootstrap-icons/icons/telephone-fill.svg';
import feed from './bootstrap-icons/icons/envelope-fill.svg';
import pen from './bootstrap-icons/icons/pen-fill.svg';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import logo from './logo.png'
import {Link} from 'react-router-dom';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

class Menu extends Component {
    state = {  };
    handleSelect = (eventKey) => alert(`selected ${eventKey}`);
    gotoWhatsapp()
    {
        window.open("https://api.whatsapp.com/send?phone=919916186546&text=Hi Nikhil Foods","_blank")
            // window.location.href="https://api.whatsapp.com/send?phone=918861514112&text=Hi YourVenue"
    }
    render() { 
        var user=localStorage.getItem('user')
        return (  
             
            <div>
                		<footer className="footer_wrap">
				
				<div className="go_top_btn1" onClick={()=>this.gotoWhatsapp()}>
				<img src={"https://firebasestorage.googleapis.com/v0/b/your-venue.appspot.com/o/whats.png?alt=media&token=0190b47a-7fce-4896-8ff6-ff10449e2f8a"}>

                </img>
				
				</div>
                </footer>
            <BrowserView>               
        <nav class="navbar navbar-fixed-top navbar-inverse">
            {/* <Dropdown>
                <Dropdown.Toggle variant="Secondary" role="button" id="dropdown-basic">
                <img src={hamburger} width="25px" height="25px"></img>
                </Dropdown.Toggle>

                <Dropdown.Menu >
                <Dropdown.Item as={Link} to="/"><img src={home}></img>&nbsp;&nbsp;Home</Dropdown.Item>
                {user && 
                <Dropdown.Item as={Link} to="/profile"><img src={person}></img>&nbsp;&nbsp;Profile</Dropdown.Item>

            }
                <Dropdown.Item><b>HELP and SUPPORT</b></Dropdown.Item>
             
                <Dropdown.Item as={Link} to="/aboutUs"><img src={info}></img>&nbsp;&nbsp;About Us</Dropdown.Item>
               
                {user && 
                <React.Fragment>
                <Dropdown.Item><b>Add my HALL</b></Dropdown.Item>
                <Dropdown.Item as={Link} to="/addHall"><img src={home}></img>&nbsp;&nbsp;Add Hall</Dropdown.Item>
                </React.Fragment>
            }
                </Dropdown.Menu>
            </Dropdown> */}
            <ul class="nav navbar-nav navbar-center">
                <div class="myLogo">
                <img src={logo} style={{width:"150px", height:"130px", marginTop:"5px"}} />  <b></b>
                </div>
            </ul> 
            <ul class="nav navbar-nav navbar-right">
                <div class="List">
               {/* < li><img src={person}></img><Link to="/payment">payment&nbsp;&nbsp;&nbsp;</Link></li> */}
                    {!user &&
                    <React.Fragment>
                {/* <li><img src={person}></img><Link to="/login">Login&nbsp;&nbsp;&nbsp;</Link></li>
                <li><img src={pen}></img><Link to="/signUp">SignUp</Link></li> */}
                </React.Fragment>
                    }
                
                </div>
            </ul>     
        </nav>
        </BrowserView>   
        <MobileView>
             <nav class="navbar navbar-fixed-top navbar-inverse">
            
            <img src={logo} style={{width:"40px", height:"60px", marginTop:"5px"}} />  
             
        </nav>
        </MobileView>
        </div>
        );
    }
}
 
export default Menu;