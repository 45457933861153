import React, { useEffect, useState } from 'react';

const Content = () => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    fetch('/content.html')
      .then(response => response.text())
      .then(data => {
        setHtml(data);
      })
      .catch(error => console.warn(error));
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Content;
