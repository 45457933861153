
import './App.css';

import { Route, Switch} from 'react-router-dom';
import React from 'react';
import Menu from './Components/menuBar.jsx';
import Content from './content.js';

function App() {
  return (
    <React.Fragment>
      <Menu></Menu>
      <Switch>
        <Route path='/' component={Content}></Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
